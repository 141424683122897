<template>
  <div class="applicationPage">
    <HeadInfo :hide-right-items="true" />
    <div class="messageParty d-flex justify-content-center align-items-center">
      <div style="margin-bottom: 3rem">
        <!-- <img
          src="@/assets/img/successfully-icon.png"
          class="successfullyPng"
          alt="success icon"
        /> -->
        <div v-html="desc" />
      </div>
    </div>
    <FooterInfo />
  </div>
</template>

<script>
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import { getPortalPageSetting } from "@/api/home";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";

export default {
  components: {
    HeadInfo,
    FooterInfo,
  },
  data() {
    return {
      msg: this.$g("submitSuccessfully"),
      desc: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    getPortalPageSetting(pageNameEnum.ApplicationSubmitSuccessful).then(
      (data) => {
        this.desc = data.data.description;
      }
    );

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "application",
      });
    },
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
};
</script>

